import { login } from "../../common/js/api";
import tools from "../../../extends/tools.js";

export default {
    init() {
        for (let i = 0; i < 12; i++) {
            this.imageList.push({
                path: require('../../../assets/img/home/login/' + (i + 2) + '.jpg')
            });
        }
        console.log(this.imageList);
    },
    jumpHome() {
        let path = '';
        if (this.role_id == 1) {
            path = '/print';
        }
        if (this.role_id == 2) {
            path = '/order/month3';
        }
        if (this.role_id == 3) {
            path = '/data_analysis/all';
        }

        this.$router.push({
            path: path
        })

    },
    kefu() {
        this.$alert('请联系客服QQ:774710010');
    },
    async kai() {
        if (!this.lock) {
            return;
        }
        //校验帐号密码   
        // if (this.formLabelAlign.name.length < 6 || this.formLabelAlign.password.length < 6) {
        //     this.$message({
        //         type: "error",
        //         message: '帐号或密码格式不合法',
        //     });
        //     return;
        // }
        this.lock = false;
        //
        login({
                account: this.formLabelAlign.name,
                password: tools.encrypt(this.formLabelAlign.password)
            }).then(res => {
                if (res.code == 200) {
                    //1.本地保存用户信息
                    localStorage.setItem('uid', res.data.info.id);
                    localStorage.setItem('isReg', false);
                    localStorage.setItem('menu', JSON.stringify(res.data.auth));
                    localStorage.setItem('userinfo', JSON.stringify(res.data.info));
                    localStorage.setItem('authorization-bearer', res.data['authorization-bearer']);
                    // 供前端路由访问
                    localStorage.setItem('loginStatus', 1);

                    // 2.本地保存菜单信息

                    //判断步骤,步骤一，需要完善资料，步骤二需要选择角色
                    if (res.data.step == 1) {
                        this.$message({
                            type: 'success',
                            message: '验证成功，请完善资料'
                        });
                        this.$router.push({
                            path: '/wan?id=' + res.data.info.id
                        });
                        return;
                    }

                    if (res.data.step == 2) {
                        this.$message({
                            type: 'success',
                            message: '验证成功，选择角色'
                        });
                        this.$router.push({
                            path: '/setRole?id=' + res.data.info.id
                        });
                        return;
                    }

                    if (res.data.step == 3) {
                        this.role_id = res.data.info.register_role_id;
                        this.jumpHome();
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                    });
                }

                this.lock = true;

            })
            .catch((err) => {
                console.log(err);
                this.$message({
                    type: "error",
                    message: '网络不畅通，请稍候重试1！',
                });

                this.lock = true;
            })

    },
    register() {
        this.$router.push({
            path: '/register'
        })
    }
}