<template>
    <div  >
        <alikey></alikey>
        <div class="top_banner">
            <div class="top_container">
                <div class="logo">
                    <img :src="logoImg" alt="">
                    <span>店铺总管</span>
                </div>
                <div class="split"></div>
                <span class="title">用心做打单，贴心为卖家</span>

                <div class="kefu" @click="kefu">
                    <img :src="kefuImg" alt="">
                    <span>有任何疑问请咨询客服</span>
                </div>
            </div>
        </div>
        <div class="div_container" >
            <div class="login">
                <!-- 登录框 -->
                <el-tabs v-model="activeName" type="border-card" :stretch="true" >
                    <el-tab-pane label="电商平台登录" name="taobao">
                        <addStore></addStore>
                    </el-tab-pane>

                    <el-tab-pane label="店铺总管登录" name="dongpo">
                        <div style="width:90%;height:100%;margin:0 auto;">
                            <el-input placeholder="用户名" v-model="formLabelAlign.name" class="input-with-select">
                                <el-button slot="prepend" icon="el-icon-user-solid" ></el-button>
                            </el-input>
                            <div style="height:10px;"></div>
                            <el-input placeholder="密码" type="password" v-model="formLabelAlign.password" class="input-with-select">
                                <el-button slot="prepend" icon="el-icon-lock" ></el-button>
                            </el-input>
                            <div style="height:30px;"></div>
                            <el-button style="width:100%;" type="primary" @click="kai">登 录</el-button>
                            <div>
                                <el-button type="text" @click="register" >注册账号</el-button>
                                <el-button type="text" style="position:relative;left:100px;" @click="kefu">忘记密码</el-button>
                            </div>
                            <div style="height:30px;"></div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>

            <div style="position:absolute;width:400px;top:60px;left:150px;color:#0058C4;">
                <div style="font-size:40px;font-weight:900;">
                    您店铺的智能管家
                </div>
                <div style="font-size:18px;margin-top:20px;">
                    主流电商平台全接入48万电商卖家的选择
                </div>
            </div>

            <div style="background:#0058C4;color:#fff;font-size:30px;font-family:宋体;
            position:absolute;left:150px;top:400px;
            width:200px;height:55px;border-radius:10px;text-align:center;line-height:55px;">立即订购</div>

            <div style="position:absolute;width:350px;top:480px;left:150px;color:#0058C4;">
                <div style="font-size:35px;font-weight:900;">
                    管账 管货 管生意
                </div>
                <div style="font-size:35px;font-weight:900;margin-top:15px;">
                    认准店铺总管
                </div>
            </div>

            <div style="position:absolute;width:700px;top:500px;right:0px;color:#f00;">
                <div style="font-size:30px;font-weight:900;">
                    店铺总管原价 8888/年  新品售价 仅需 /2998/年
                </div>
            </div>

        </div>

        <div style="max-width: 98;min-width: 1024px;margin:0 auto;" >
            <img :src="item.path" style="width:100%;" v-for="(item,key) in imageList" :key="key" alt="">
        </div>
    </div>
</template>

<script>
    import tools from "../../../extends/tools.js";
    import Alikey from '../../../components/common/alikey.vue';
    import methods from './methods.js'; 
    import addStore from '../../shop/base/addStore2.vue'; 
    
    export default {
        name: "HomeHospital",
        components:{
            Alikey,addStore
        },
        data() {
            return {
                labelPosition: 'right',
                lock:true,
                role_id:0,
                formLabelAlign: {
                    name: '',
                    password:"",
                },
                windowHeight:'1000px',
                logoImg:require('../../../assets/img/home/logo.png'),
                kefuImg:require('../../../assets/img/home/kefu.png'),
                activeName:'taobao',
                imageList:[]
            }
        },
        methods: methods,
        created(){
            this.init();    
        }
    }
</script>

<style scoped lang="less">
    @import url('./less/home.less');
    .con{
        width: 100%;
        height: 940px;
        background-size: cover;
        background-position: center;
    }
     
</style>