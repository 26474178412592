<template>
    <div>
        <Alibaba></Alibaba>
        <div class="home" v-if="!fromAlibaba">
            <home-hospital ></home-hospital>
            <div style="height:30px;line-height:30px;text-align:center;background:#3d3d3d;">
            <a href="https://beian.miit.gov.cn/" style="font-size:16px;color:#fff;"> 
            <img :src="require('../../assets/img/home/police.png')" style="width:20px;height:20px;">
            粤ICP备14037482号-5
            </a>
            </div>
        </div>

        <div v-if="fromAlibaba" style="width:100%;height:500px;line-height:500px;text-align:center;font-size:24px;color:#999;">
            跳转中，请稍后...
        </div>

    </div>

</template>

<script>
    import Qs from 'qs';
    import HomeHospital from "./base/HomeHospital";
    import {loginJump} from "../common/js/api";
    import tools from '../../extends/tools';
    import Alibaba from "../../components/common/alibaba.vue";

    export default {
        name: "Home",
        components:{
            HomeHospital,Alibaba
        },
        data(){
            return {
                fromAlibaba:false,
                userInfo:{}
            }
        },
        created(){
            let authorization_bearer = this.$route.query.authorization_bearer;
            if(!authorization_bearer){
                return;
            }
            this.fromAlibaba = true;
            // let loader = tools.loading(null,this);
            loginJump({
                authorization_bearer:authorization_bearer
                }).then(res => {
                    if(res.code==200){
                        //1.本地保存用户信息
                        localStorage.setItem('uid',res.data.info.id);
                        localStorage.setItem('isReg',false);
                        localStorage.setItem('menu',JSON.stringify(res.data.auth));
                        localStorage.setItem('userinfo',JSON.stringify(res.data.info));
                        this.userInfo = res.data.info;
                        localStorage.setItem('authorization-bearer',res.data['authorization-bearer']);
                        // 供前端路由访问
                        localStorage.setItem('loginStatus',1);

                        // 2.本地保存菜单信息
                        
                        //判断步骤,步骤一，需要完善资料，步骤二需要选择角色
                        if(res.data.step == 1){
                            this.$message({
                                type:'success',
                                message:'验证成功，请完善资料'
                            });
                            this.$router.push({
                                path:'/wan?id='+res.data.info.id
                            });
                            return;
                        }

                        if(res.data.step == 2){
                            this.$message({
                                type:'success',
                                message:'验证成功，选择角色'
                            });
                            this.$router.push({
                                path:'/setRole?id='+res.data.info.id
                            });
                            return;
                        }

                        if(res.data.step == 3){
                            this.role_id = res.data.info.register_role_id;
                            setTimeout(()=>{
                                this.jumpHome();
                            },1000)
                        }
                    }else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }


                    // tools.loading(loader);
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: '网络不畅通，请稍候重试1！',
                    });
                    
                    // tools.loading(loader);
                })
        },
        methods:{
            jumpHome(){
                let path = '';
                if(this.role_id == 1){
                    path = '/print';
                }
                if(this.role_id == 2){
                    path = '/order/month3';
                }
                if(this.role_id == 3){
                    path = '/order/month3';
                }
                
                this.$router.push({
                    path:path
                })
            },
        },
        // filters:{
        //     TitleFilter(value){
        //         //console.log(value.split(0).join().split(2)[2].split(',')[1]);
        //         return value.split(0).join().split(2)[2].split(',')[1]
        //     }
        // },

    }
</script>

<style scoped lang="less">
.home{
    .home-le{
        display: flex;
        justify-content: center;
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        margin: auto;
        z-index:4;
        width:100px;
        max-height:346px;
        padding:15px 0;
        background-color:#910000;
        cursor: pointer;
        p{
            //width:46px;
            line-height:29px;
            letter-spacing:5px;
            font-size:18px;
            color:#fff;
            writing-mode: vertical-lr;
        }
        .img1{
            position: absolute;
            right:0;
            top:0;
            width:25px;
        }
    }
    .home-ri{
        display: flex;
        justify-content: center;
        position:fixed;
        top:0;
        bottom:0;
        right:0;
        margin: auto;
        z-index:4;
        width:100px;
        max-height:346px;
        padding:15px 0;
        background-color:#910000;
        cursor: pointer;
        p{
            line-height:29px;
            letter-spacing:13px;
            font-size:18px;
            color:#fff;
            writing-mode:vertical-lr;
        }
        .img1{
            position: absolute;
            right:0;
            top:0;
            width:25px;
        }
     }
}


</style>
