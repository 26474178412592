<template>
  <div>
       <!-- 创建 -->
        <div style="margin:0 auto;background:#fff;padding:0 0 50px 0;">
            <div slot="footer" class="dialog-footer" style="text-align:center;">
                <img :src="currentItem.icon" style="width:80px;height:80px;" alt="">
                <div></div>
                <el-button style="background:#ff7000;color:#fff;height:50px;width:150px;margin-top:13px;" @click="getAuth"
                >{{currentItem.name}}登录</el-button>
            </div>

            <div style="width:90%;height:1px;background-color:#ccc;margin:20px auto 20px auto;"></div>
            <div style="text-align:center;">
                其他平台登录
            </div>

            <div class="platform" style="margin-top:10px;">
                <div class="items" v-for="(item,key) in listData" :key="key" @click="setAuth(item)">
                    <img :src="item.icon" alt="" >
                    <p style="margin:5px 0 0 0">{{item.name}}</p>
                </div>
            </div>
        </div> 
        
  </div>
</template>

<script>
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            saveData:{
                
            },
            currentItem:{},
            listData:[],
            format:'YYYY-MM-DD HH:mm:ss'
        }
    },
    methods:{
        setAuth(item){
            this.currentItem = item;
            this.getAuth();
        },
        getAuth(){
            location.href = this.currentItem.request_address;
        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        //插入图片
        pickPicture(file) {
            this.uploadLoading = false;
            this.saveData.portrait = file.font;
            this.imageUrl = this.baseUrl+file.font;
        },
        save(){
            
        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        //获取电商平台列表
        apis.getShopPlatform2().then((res)=>{
            this.listData = res.data;
            this.currentItem = this.listData[1];
        }).catch(error=>{
            console.log(error);
            this.$message('网络或程序错误，请稍候重试！');
        });
    }
}
</script>

<style lang="less">
    .platform{
        display: flex;justify-content:center;
        .items{
            width:60px;height:60px;margin:10px 0 0 20px;cursor: pointer;text-align:center;
            img{
                width:100%;
            }
        }
    }
</style>